import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RaciMatrix from './components/RaciMatrix';
import StakeholderDetail from './components/StakeholderDetail';
import RoleDetail from './components/RoleDetail';
import TaskDetail from './components/TaskDetail';
import FlowDetail from './components/FlowDetail';
import './App.css';

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<RaciMatrix />} />
        <Route path="/stakeholder/:id" element={<StakeholderDetail />} />
        <Route path="/role/:role" element={<RoleDetail />} />
        <Route path="/task/:id" element={<TaskDetail />} />
        <Route path="/flow" element={<FlowDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
