import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const legend = require('../assets/flow.png');

const FlowDetail = () => {
  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg raciflow">
        <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">IT Department Process Flow</h1>
        <div>
            <img className="h-auto mx-auto racilegend" src={legend} alt="Unita RACI Legend" />
        </div>

        <div className="mt-6">
            <Link to="/" className="text-blue-600 hover:underline">Back to UNITA IT Department RACI Matrix</Link>
        </div>
    </div>
  );
};

export default FlowDetail;
