import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import matrixData from '../raci.json';

const logo = require('../assets/unita-logo.jpg');
const legend = require('../assets/legend.jpg');

const RaciMatrix = () => {
  const [tasks, setTasks] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);

  useEffect(() => {
    setTasks(matrixData.tasks);
    setStakeholders(matrixData.stakeholders);
  }, []);

  // Map role to their respective colors
  const roleColorMap = {
    R: 'bg-raci-R text-gray-800',  // R role color (Light purple)
    A: 'bg-raci-A text-gray-800',  // A role color (Light teal)
    C: 'bg-raci-C text-gray-800',  // C role color (Light yellow)
    I: 'bg-raci-I text-gray-800',  // I role color (Light pink)
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg racimatrix">
      <img className="h-auto max-w-xs racilogo" src={logo} alt="Unita LOGO" />
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">UNITA IT Department RACI Matrix</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-fixed border-collapse">
        <thead>
            <tr className="bg-custom-lightblue text-gray-700 sticky top-0 z-10">
              <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider bg-custom-lightblue border-r border-indigo-500">
              </th>
              {stakeholders.map(stakeholder => (
                <th key={stakeholder.id} className="px-6 py-3 text-center text-sm font-semibold uppercase tracking-wider border-indigo-500">
                  <Link to={`/stakeholder/${stakeholder.id}`} className="hover:underline ">
                    {stakeholder.person}
                  </Link>
                </th>
              ))}
            </tr>
          </thead>
          <thead>
            <tr className="bg-custom-lightblue text-gray-700 sticky top-0 z-10">
              <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider bg-custom-lightblue border-b border-r border-indigo-500">
              Activity/Task
              </th>
              {stakeholders.map(stakeholder => (
                <th key={stakeholder.id} className="px-6 py-3 text-center text-sm font-semibold uppercase tracking-wider border-b border-indigo-500">
                  <Link to={`/stakeholder/${stakeholder.id}`} className="hover:underline ">
                    {stakeholder.name}
                  </Link>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-200`}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 bg-custom-lightblue border-r border-indigo-500">
                  <Link to={`/task/${task.id}`} className="text-blue-600 hover:underline">
                    {task.name}
                  </Link>
                </td>
                {stakeholders.map(stakeholder => (
                  <td key={stakeholder.id} className={`w-full h-full p-0 text-center 
                    ${roleColorMap[task.roles[stakeholder.id]]} hover:bg-opacity-75 transition-colors duration-200`}
                  >
                    {task.roles[stakeholder.id] ? (
                      <Link to={`/role/${task.roles[stakeholder.id]}`} className={`block w-full h-full py-2 text-xs font-semibold rounded 
                        ${roleColorMap[task.roles[stakeholder.id]]} hover:bg-opacity-75 transition-colors duration-200`}
                      >
                        {task.roles[stakeholder.id]}
                      </Link>
                    ) : (
                      <span className="block w-full h-full bg-transparent text-gray-400">-</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <img className="h-auto max-w-lg mx-auto racilegend" src={legend} alt="Unita RACI Legend" />
      </div>
      <div className="mt-6">
        <Link to="/flow" className="text-blue-600 hover:underline">IT Department Process Flow</Link>
      </div>
    </div>
  );
};

export default RaciMatrix;
