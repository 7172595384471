import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import matrixData from '../raci.json';

const StakeholderDetail = () => {
  const { id } = useParams();
  const [stakeholder, setStakeholder] = useState(null);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const selectedStakeholder = matrixData.stakeholders.find(st => st.id === id);
    setStakeholder(selectedStakeholder);
    setTasks(matrixData.tasks);
  }, [id]);

  if (!stakeholder) {
    return <p>Loading...</p>;
  }

  const getRolesForStakeholder = () => {
    return tasks.filter(task => task.roles[stakeholder.id]);
  };

  return (
    <div className="stakeholder-detail container mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-blue-600 mb-4">{stakeholder.name}</h1>

      {/* Summary */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Summary</h2>
        <p className="text-gray-600">{stakeholder.summary}</p>
      </div>

      {/* Mission */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Mission</h2>
        <p className="text-gray-600">{stakeholder.mission}</p>
      </div>

      {/* Roles Table */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Roles</h2>
        <div className="overflow-x-auto">
          <table className="w-full table-fixed border-collapse border border-gray-300">
            <thead>
              <tr className="bg-custom-lightblue text-gray-700">
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Task</th>
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Role</th>
              </tr>
            </thead>
            <tbody>
              {getRolesForStakeholder().map(task => (
                <tr key={task.name} className="hover:bg-gray-100 transition-colors duration-200">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    <Link to={`/task/${task.id}`} className="text-blue-600 hover:underline">
                      {task.name}
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-700">
                    <span className={`block px-2 py-1 rounded ${task.roles[stakeholder.id] === 'R' ? 'bg-raci-R' : task.roles[stakeholder.id] === 'A' ? 'bg-raci-A' : task.roles[stakeholder.id] === 'C' ? 'bg-raci-C' : 'bg-raci-I'}`}>
                      {task.roles[stakeholder.id] == 'R' ? 'Responsible' : task.roles[stakeholder.id] == 'A' ? 'Accountable' : task.roles[stakeholder.id] == 'C' ? 'Consulted' : 'Informed'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Main Tasks */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Main Tasks</h2>
        <ul className="list-disc list-inside text-gray-600">
          {stakeholder.mainTasks.map(task => (
            <li key={task}>
              {task}
            </li>
          ))}
        </ul>
      </div>

      {/* Additional Info */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Additional Info</h2>
        <div className="text-gray-600" dangerouslySetInnerHTML={{ __html: stakeholder.additionalInfo }} />
      </div>

      <div className="mt-6">
        <Link to="/" className="text-blue-600 hover:underline mr-4">Back to UNITA IT Department RACI Matrix</Link>
      </div>
    </div>
  );
};

export default StakeholderDetail;
