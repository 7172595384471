import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import matrixData from '../raci.json';

const TaskDetail = () => {
  const { id } = useParams(); // Get the task name from the URL
  const [task, setTask] = useState(null);
  const [stakeholders, setStakeholders] = useState([]);

  useEffect(() => {
    // Find the task based on the task id from the URL
    const selectedTask = matrixData.tasks.find(t => (t.id === id));
    setTask(selectedTask);

    // Set stakeholders from matrix data
    setStakeholders(matrixData.stakeholders);
  }, [id]);

  if (!task) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-blue-600 mb-4">Task: {task.name}</h1>

      {/* Task Description */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Description</h2>
        <p className="text-gray-600">{task.description}</p>
      </div>

      {/* Roles Table */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Stakeholders and Roles</h2>
        <div className="overflow-x-auto">
          <table className="w-full table-fixed border-collapse border border-gray-300">
            <thead>
              <tr className="bg-custom-lightblue text-gray-700">
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Stakeholder</th>
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Role</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(task.roles).map(([stakeholderId, role]) => (
                <tr key={stakeholderId} className="hover:bg-gray-100 transition-colors duration-200">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    <Link to={`/stakeholder/${stakeholders.find(st => st.id === stakeholderId)?.id}`} className="text-blue-600 hover:underline">
                    {stakeholders.find(st => st.id === stakeholderId)?.name}
                    </Link>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-700">
                    <span className={`block px-2 py-1 rounded ${role === 'R' ? 'bg-raci-R' : role === 'A' ? 'bg-raci-A' : role === 'C' ? 'bg-raci-C' : 'bg-raci-I'}`}>
                    {role == 'R' ? 'Responsible' : role == 'A' ? 'Accountable' : role == 'C' ? 'Consulted' : 'Informed'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-6">
        <Link to="/" className="text-blue-600 hover:underline">Back to UNITA IT Department RACI Matri</Link>
      </div>
    </div>
  );
};

export default TaskDetail;
