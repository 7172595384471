import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import matrixData from '../raci.json';

const RoleDetail = () => {
  const { role } = useParams(); // Get the role from the URL
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Find all tasks where the role matches for any stakeholder
    const filteredTasks = matrixData.tasks.filter(task =>
      Object.values(task.roles).includes(role)
    );
    setTasks(filteredTasks);
  }, [role]);

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold text-blue-600 mb-4">Role: {role == 'R' ? 'Responsible' : role == 'A' ? 'Accountable' : role == 'C' ? 'Consulted' : 'Informed'}</h1>

      {/* Description */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Stakeholders with Role: {role == 'R' ? 'Responsible' : role == 'A' ? 'Accountable' : role == 'C' ? 'Consulted' : 'Informed'}</h2>
        <p className="text-gray-600">Below are the tasks and stakeholders who have the role of <strong>{role == 'R' ? 'Responsible' : role == 'A' ? 'Accountable' : role == 'C' ? 'Consulted' : 'Informed'}</strong> in those tasks.</p>
      </div>

      {/* Stakeholders Table */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-2">Tasks and Stakeholders</h2>
        <div className="overflow-x-auto">
          <table className="w-full table-fixed border-collapse border border-gray-300">
            <thead>
              <tr className="bg-custom-lightblue text-gray-700">
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Task</th>
                <th className="px-6 py-3 text-left text-sm font-semibold uppercase tracking-wider">Stakeholder</th>
              </tr>
            </thead>
            <tbody>
              {tasks.length === 0 ? (
                <tr>
                  <td className="px-6 py-4 text-gray-700" colSpan={2}>
                    No stakeholders found for this role.
                  </td>
                </tr>
              ) : (
                tasks.map(task => (
                  Object.entries(task.roles).map(([stakeholderId, stakeholderRole]) => (
                    stakeholderRole === role && (
                      <tr key={`${task.name}-${stakeholderId}`} className="hover:bg-gray-100 transition-colors duration-200">
                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                          <Link to={`/task/${task.id}`} className="text-blue-600 hover:underline">
                            {task.name}
                          </Link>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-700">
                          <Link to={`/stakeholder/${matrixData.stakeholders.find(st => st.id === stakeholderId)?.id}`} className="text-blue-600 hover:underline">
                            {matrixData.stakeholders.find(st => st.id === stakeholderId)?.name}
                          </Link>
                        </td>
                      </tr>
                    )
                  ))
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-6">
        <Link to="/" className="text-blue-600 hover:underline">Back to UNITA IT Department RACI Matrix</Link>
      </div>
    </div>
  );
};

export default RoleDetail;
